.WorkspaceList {
  padding-left: 10px;
  padding-right: 10px;
}

.WorkspaceList-title {
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}

.WorkspaceList .workspace-message {
  padding: 10px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
}

.WorkspaceList-container {
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  border-radius: 10px;
}

.WorkspaceList-container .workspace {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #fff;
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
}

.WorkspaceList-container .workspace:hover {
  background-color: #fff;
  color: #000;
}

.WorkspaceList-container .workspace.selected:hover {
  background-color: transparent;
  color: #fff;
}

.WorkspaceList-container .workspace:last-child {
  border: none;
}
