.TicketForm-container {
  text-align: left;
  padding: 15px;
}

.TicketForm-container .TicketForm-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 0px;
  background: #fff;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-top: 15px;
}

.TicketForm-container .TicketForm-button:hover {
  opacity: 0.8;
}

.TicketForm .TicketForm-field {
  margin-bottom: 20px;
}
