.TicketNotesField-label-container {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 10px;
}

.TicketNotesField-notes {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  resize: none;
  background: transparent;
  color: #fff;
  font-size: 18px;
  width: 100%;
  box-sizing: border-box;
  border-width: 3px;
  border-radius: 5px;
  border-color: #fff;
  height: 150px;
  font-family: sans-serif;
  padding: 10px;
}

.TicketNotesField-notes::placeholder {
  color: #acacac;
}
