.App {
  text-align: center;
  color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App .home-header {
  font-size: 18px;
}

@media only screen and (min-width: 576px) {
  .home-body {
    max-width: 576px;
  }
}

.App .home-body {
  width: 100%;
  margin: auto;
}

.App .home-footer {
  margin-top: auto;
  padding-bottom: 30px;
}

.App .home-footer .copyright {
  max-width: 350px;
  margin: auto;
  color: #5a5a5a;
  font-size: 14px;
}

.App .home-message {
  font-size: 16px;
}

.App .home-button-wrapper {
  margin-top: 20px;
  padding: 10px;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.App .home-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  cursor: pointer;
  width: 100%;
}

.App .error-message {
  padding: 10px;
  background-color: rgb(255, 88, 88);
  border-radius: 5px;
}
