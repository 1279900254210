.TicketTypeSelectionField-container {
  font-size: 18px;
}

.TicketTypeSelectionField-container .TicketTypeSelectionField-options {
  display: none;
  position: relative;
  z-index: 1;
}

.TicketTypeSelectionField-container .TicketTypeSelectionField-options.open {
  display: block;
}

.TicketTypeSelectionField-container
  .TicketTypeSelectionField-options
  .TicketTypeSelectionField-options-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background: #000;
  padding: 0px;
  border-width: 3px;
  border-color: #fff;
  border-style: solid;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.TicketTypeSelectionField-container .TicketTypeSelectionField-option {
  cursor: pointer;
  padding: 10px;
  border-bottom-width: 3px;
  border-bottom-color: #fff;
  border-bottom-style: solid;
}

.TicketTypeSelectionField-container .TicketTypeSelectionField-option:hover {
  background-color: #fff;
  color: #000;
}

.TicketTypeSelectionField-container
  .TicketTypeSelectionField-option:last-child {
  border-bottom: none;
}

.TicketTypeSelectionField-container .TicketTypeSelectionField-value {
  cursor: pointer;
  border-width: 3px;
  border-color: #fff;
  border-style: solid;
  border-radius: 5px;
  padding: 10px;
  position: relative;
}

.TicketTypeSelectionField-container .TicketTypeSelectionField-placeholder {
  color: #acacac;
}

.TicketTypeSelectionField-container
  .TicketTypeSelectionField-value
  .TicketTypeSelectionField-arrow {
  position: absolute;
  right: 10px;
}

.TicketTypeSelectionField-container .TicketTypeSelectionField-value.open {
  margin-bottom: 0px;
  border-bottom-color: transparent;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #fff;
  color: #000;
}
