.submit-success-container {
  text-align: left;
  padding: 10px;
}

.submit-success-container .submit-success-title {
  font-size: 36px;
  font-weight: bold;
}

.submit-success-container .submit-success-message {
  font-size: 18px;
  margin-top: 10px;
}

.submit-success-container .submit-success-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 0px;
  background: #fff;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-top: 15px;
}
