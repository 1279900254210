.ProjectSearchField .ProjectSearchResults {
  position: relative;
  width: 100%;
}

.ProjectSearchField .ProjectSearchResults-container.search-results {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: #000;
  display: block;
  padding: 0px;
  border-width: 3px;
  border-color: #fff;
  z-index: 1;
  border-style: solid;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  text-align: left;
}

.ProjectSearchField .ProjectSearchResults.hidden {
  display: none;
}
