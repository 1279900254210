.TicketForm-field .ProjectSearchField-input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  background: #000;
  color: #fff;
  border-radius: 5px;
  border-width: 3px;
  border-color: #fff;
  border-style: solid;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  height: 50px;
}

.TicketForm-field .ProjectSearchField-input::placeholder {
  color: #acacac;
}

.TicketForm-field .ProjectSearchField-input:focus {
  outline: none;
}

.TicketForm-field .ProjectSearchField-input.loaded {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-width: 0px;
}

.ProjectSearchField-selected-container {
  position: relative;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  background: #000;
  color: #fff;
  border-radius: 5px;
  border-width: 3px;
  border-color: #fff;
  border-style: solid;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
}

.ProjectSearchField-selected-container .ProjectSearchField-change-button {
  position: absolute;
  right: 10px;
  font-size: 12px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 0px;
  background: #fff;
  color: #000;
  font-weight: bold;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 7.5px;
  padding-right: 7.5px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.ProjectSearchField-selected-container .ProjectSearchField-change-button:hover {
  opacity: 0.8;
}

.ProjectSearchField-selected-container
  .ProjectSearchField-change-button:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
