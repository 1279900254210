.ProjectSearchResult-container {
  padding: 0px;
  border-bottom-width: 3px;
  border-bottom-color: #fff;
  border-bottom-style: solid;
}

.ProjectSearchResult-container:last-child {
  border: none;
}

.ProjectSearchResult-container .ProjectSearchResult-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
}

.ProjectSearchResult-container .ProjectSearchResult-button:hover {
  background-color: #fff;
  color: #000;
}
